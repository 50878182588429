import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FavoriteButton, WaitButton } from 'components';
// import { Star as StarIcon } from 'components/icons';
import { Button, LazyImage, UpDownButton } from 'components/shared';
import styles from './ProductCard.module.scss';

const ProductCard = ({
  product,
  currency,
  queryKey,
  inCart,
  onClick,
  onCartClick,
  onUpDownClick,
}) => {
  const { t } = useTranslation();

  const {
    id,
    slug,
    name,
    img,
    weight,
    // rating,
    qty,
    isFavorite,
    discount,
    oldPrice,
    currentPrice,
    isWait,
    pricePerKg,
    isWeightGoods,
  } = product;

  const currentPriceWeightGood = discount
    ? (pricePerKg * (1 - discount / 100)).toFixed(2)
    : pricePerKg;

  return (
    <div className={styles.root} onClick={() => onClick({ productSlug: slug, queryKey })}>
      <div className={styles.top}>
        {/* {rating && (
          <span className={styles.rating}>
            <StarIcon color="text" size={10} />
            <p>{rating}</p>
          </span>
        )} */}
        {product.advIcon && <img className={styles.advIcon} src={product.advIcon} alt="Adv icon" />}
        <FavoriteButton isFavorite={isFavorite} productId={id} queryKey={queryKey} />
      </div>

      <div className={styles.image}>
        <LazyImage src={img} alt={name} width={140} height={140} />
      </div>

      {discount && <span className={styles.discount}>-{discount}%</span>}

      <div className={styles.info}>
        <div className={styles.price}>
          <p className={clsx(styles.priceCurrent, oldPrice && styles.priceDiscounted)}>
            {isWeightGoods
              ? `${currentPriceWeightGood} ${currency}/кг`
              : `${currentPrice} ${currency}`}
          </p>

          {oldPrice && (
            <p className={styles.priceOld}>
              {isWeightGoods
                ? `${pricePerKg} ${currency}/${t('product:kg')}`
                : `${oldPrice} ${currency}`}
            </p>
          )}
        </div>
        <div className={styles.title}>
          <span className={styles.name}>{name}</span>
        </div>
        <div className={styles.title}>
          <span className={styles.weight}>
            {isWeightGoods ? `(${t('product:quant')} ${weight})` : weight}
          </span>
        </div>
      </div>

      <div className={styles.bottom}>
        {inCart ? (
          <UpDownButton
            value={inCart.qty}
            maxValue={inCart.maxQty}
            onClick={(newQty, e) => {
              e.stopPropagation();
              onUpDownClick({ productId: id, qty: newQty });
            }}
          />
        ) : (
          <Button
            data-gtm-id="AddToCartButton"
            size="small"
            disabled={qty <= 0}
            onClick={e => {
              e.stopPropagation();
              onCartClick(product);
            }}
          >
            {qty <= 0 ? t('product:zeroQty') : t('product:toCart')}
          </Button>
        )}
        {qty <= 0 && <WaitButton isWait={isWait} productId={id} queryKey={queryKey} />}
      </div>
    </div>
  );
};

export default ProductCard;
