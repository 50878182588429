import { useEffect } from 'react';
import { MODAL_EVENTS } from './events';

const useModalsEvents = ctx => {
  const events = {
    open: event => ctx.openModal(event.detail),
    close: event => ctx.closeModal(event.detail),
  };

  useEffect(() => {
    Object.keys(events).forEach(event => {
      //@ts-ignore
      window.addEventListener(MODAL_EVENTS[event], events[event]);
    });
    return () => {
      Object.keys(events).forEach(event => {
        //@ts-ignore
        window.removeEventListener(MODAL_EVENTS[event], events[event]);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useModalsEvents;
