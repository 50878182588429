import React from 'react';
import clsx from 'clsx';
import { capitalize } from 'utils/helpers';
import styles from './SvgIcon.module.scss';

/* TODO:
- use unions for sizes
- remove isHoverable (create IconButton component ?_?)
*/

const SvgIcon = React.forwardRef((props, ref) => {
  const { children, className, classes, style, color, size, isHoverable, onClick, ...otherProps } =
    props;

  return (
    <svg
      ref={ref}
      className={clsx(
        styles.root,
        (isHoverable || onClick) && styles.isHoverable,
        styles[`color${capitalize(color)}`],
        className,
        classes.root,
      )}
      style={{
        fontSize: size,
        ...style,
      }}
      viewBox="0 0 24 24"
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </svg>
  );
});

SvgIcon.defaultProps = {
  children: null,
  className: null,
  classes: {},
  style: null,
  color: 'default',
  size: null,
  isHoverable: false,
  onClick: null,
};

export default SvgIcon;
