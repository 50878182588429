import { useReducer } from 'react';

const ACTIONS = {
  openModal: 'modals-state:open-modal',
  closeModal: 'modals-state:close-modal',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.openModal: {
      const { modalId, modalComponent } = action.payload;
      state.modals[modalId] = modalComponent;
      return { ...state };
    }
    case ACTIONS.closeModal: {
      const { modalId } = action.payload;
      delete state.modals[modalId];
      return { ...state };
    }
    default:
      return state;
  }
};

const useModalsState = () => {
  const [state, dispatch] = useReducer(reducer, { modals: {} });

  const openModal = ({ modalId, modalComponent }) => {
    dispatch({ type: ACTIONS.openModal, payload: { modalId, modalComponent } });
  };

  const closeModal = ({ modalId }) => {
    dispatch({ type: ACTIONS.closeModal, payload: { modalId } });
  };

  return { ...state, openModal, closeModal };
};

export default useModalsState;
