import React, { Fragment } from 'react';
import ModalsContext from './ModalsContext';
import useModalsEvents from './useModalsEvents';
import useModalsState from './useModalsState';

const ModalsContextProvider = ({ children }) => {
  const state = useModalsState();

  useModalsEvents(state);

  return (
    <ModalsContext.Provider value={state}>
      {children}
      {Object.entries(state.modals).map(([modalId, modalComponent]) => (
        <Fragment key={modalId}>{modalComponent}</Fragment>
      ))}
    </ModalsContext.Provider>
  );
};

export default ModalsContextProvider;
